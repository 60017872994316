@import url('./design-system.css');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing:border-box;
}

body{
  font-size: 16px;
  line-height: 26px;
  font-family: var(--ffMain);
  color:var(--clrVeryDarkBlue);
}

/*Main Page*/
.main-page-wrapper{
background-color: var(--clrLightGrey);
min-height: 100vh;
transition: background var(--trTheme);
padding-bottom: 208px;
position: relative;
}

/*Main Page Dark Theme*/
.main-page-wrapper.dark-theme {
  background:var(--clrMidnight)
}

/*Navbar*/
.navbar-wrapper{
  position: absolute;
  width: 100%;
  height: 160px;
  background: var(--clrViolet);
  border-radius:  0 0 0 100px;
  z-index: 10;
  overflow: hidden;
}

.navbar-body{
  height: 100%;
  display: flex;
  justify-content:space-between;
  padding-top: 45px;
}

.navbar-body .navbar-title{
  color:#fff;
  cursor: pointer;
}

.navbar-body .navbar-title:hover {
  color:var(--clrLightGrey);
}

/*Triangle Patterns in Navbar*/
.triangle{
position: absolute;
width: 515px;
height: 0;
border-right:258px solid transparent;
border-left:258px solid transparent;
border-bottom: 515px solid #6c76df;
}

.triangle:nth-child(1) {
  transform: rotate(-40deg);
  left:-200px;
  top:-80px;
}

.triangle:nth-child(2){
  left:450px;
  top:-300px;
  border-bottom-right-radius: 30%;
  border-bottom-left-radius: 30%;
  transform: rotate(90deg);
}

.triangle:nth-child(3){
  display: none;
  right:00px;
}


/*Dark Theme*/
.navbar-wrapper.dark-theme  .input-wrapper:not(:last-child) {
  border-right:1px solid #75758b
}

/*Switch Box styling*/
.switchbox-wrapper{
  width: 112px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switchbox-wrapper input[type="checkbox"]{
 position: absolute;
 visibility: hidden; 
}

.switchbox-wrapper label{
  width: 48px;
  height: 24px;
  background: #fff;
  border-radius:16px;
  position: relative;
}

.switchbox-wrapper label::after{
 content: '';
 position: absolute;
 width: 14px;
 height: 14px;
 background: var(--clrViolet);
 border-radius: 50%;
 top:50%;
 transform: translateY(-50%);
 left: 5px;
 transition: all 0.15s ease-in-out
}

.switchbox-wrapper label:hover::after{
  background: var(--clrLightViolet);
}

/*Move the pseudo element when the checkbox is checked*/
.switchbox-wrapper input:checked + label::after {
 left: calc(100% - 19px);
}

/*Search Bar*/
.searchbar-wrapper{
 width: 1110px;
 height: 80px;
 background: #fff;
 color:var(--clrVeryDarkBlue);
 border-radius: var(--borderRadiusMain);
 display: grid;
 grid-template-columns: 463px 300px 345px;
 align-items: center;
 transition: background var(--trTheme);
 transform: translateY(120px);
}

.searchbar-wrapper label{
  /*color:var(--clrVeryDarkBlue);*/
  transition: color var(--trTheme);
}

/*Dark Theme SearchBar*/
.searchbar-wrapper.dark-theme{
  background: var(--clrVeryDarkBlue);
}

.searchbar-wrapper.dark-theme label{
  color:#fff;
}

.searchbar-wrapper{
  z-index: 10;
}
.searchbar-wrapper .input-wrapper{
  height: 100%;
  display: flex;
  align-items: center;
  border-right:1px solid var(--clrLightGrey);
  position: relative;  
}

/*Searchbar Mobile Modal*/
.searchbar-wrapper-mobile-overlay {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.searchbar-wrapper-mobile{
  width: 327px;
  height: 217px;
  padding: 24px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.searchbar-wrapper-mobile.dark-theme{
  background: var(--clrVeryDarkBlue);
}

.searchbar-wrapper-mobile::after{
  content:'';
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--clrLightGrey);
  left:0;
  top:72px;
}

/*Searchbar Mobile Location*/
.searchbar-wrapper-mobile .input-wrapper.location-input{
  display: flex;
  padding-bottom: 24px;
}

.searchbar-wrapper-mobile.dark-theme .location-input input{
  background: var(--clrVeryDarkBlue);
  color:#fff;
}

.searchbar-wrapper-mobile .location-input input{
border: none;
font-size: 16px;
margin-left: 16px;
}

/*Full time checkbox Mobile*/
.searchbar-wrapper-mobile .input-wrapper.fulltime-input{
margin-top: 24px;
}

.searchbar-wrapper-mobile .full-time-label-mobile {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-weight: bold;
}

.searchbar-wrapper-mobile.dark-theme .full-time-label-mobile {
  color:#fff;
}

.searchbar-wrapper-mobile .full-time-label-mobile::after{
  position: absolute;
  content:'';
  left:0;
  width: 24px;
  height: 24px;
  background: var(--clrDarkGrey);
}

.searchbar-wrapper-mobile input[type="checkbox"]:checked + .full-time-label-mobile svg{
  z-index: 2;
  opacity: 1;
  top:2px;
}

.mobile-btn{
  background: var(--clrViolet);
  color:#fff;
  width: 100%;
  height:48px;
  margin-top: 24px;
}

.mobile-btn.dark-theme:disabled{
  background: var(--clrDarkGrey);
}

/*Icons in Keyword Input*/
.keyword-icon-container{
  position: absolute;
}

.input-wrapper:last-child {
  border: none;
}

.searchbar-wrapper input {
  font-size: 16px;
  font-family:inherit;
  border: none;
  caret-color:var(--clrViolet);
  height: 24px;
  background: inherit;
  color:inherit;
}

.keyword-input input {
  margin-left: 72px;
  width: 270px;
}

.searchbar-wrapper svg{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}

.keyword-input svg{
  left:32px
}

.location-input svg{
  left:23px;
}

.searchbar-wrapper input:focus{
  outline:none;
}

.location-input input{
  margin-left: 56px;
  width: 170px;
}

.searchbar-wrapper .fulltime-input{
 display: flex;
 justify-content: space-between;
 position: relative;
}

.searchbar-wrapper .fulltime-input label{
  position: relative;
  font-weight: bold;
  margin-left: 32px;
  display: flex;
  align-items: center;
}

/*Checkmark styling*/
.fulltime-input  svg{
  position: absolute;
  left:4px;
  opacity: 0;
}

.fulltime-input input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.fulltime-input input[type='checkbox']:checked ~ label svg{
  opacity: 1;
}

.fulltime-input label::before{
  content:'';
  width: 24px;
  height: 24px;
  background:var(--clrViolet);
  border-radius: 3px;
  margin-right: 16px;
}

.fulltime-input button{
  margin-right: 16px;
}

.fulltime-input button:disabled{
  background: var(--clrLightGrey);
  cursor: default
}

.fulltime-input button.dark-theme:disabled{
  background: var(--clrDarkGrey);
}

.clear-button{
  position: absolute;
  top:-87px;
  left:0;
}

/*Job Cards Section*/
.job-cards-page-wrapper{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 30px;
  row-gap: 40px;
  margin-top: 225px;
}

.no-results-error.dark-theme{
  color:var(--clrLightGrey)
}

/*Load More Button*/
.load-more{
display:block;
position: absolute;
bottom:-104px;
left:50%;
transform:translateX(-50%);
}

.job-card-wrapper{
  position: relative;
  background: #fff;
  width: 350px;
  height: 228px;
  border-radius: 10px;
  transition: background var(--trTheme);
}

.job-position-wrapper h3{
  transition:color var(--trTheme)
}

.job-position-wrapper h3:hover{
  color:var(--clrDarkGrey);
  transition: var(--trMain);
}

/*Dark Theme*/
.job-card-wrapper.dark-theme{
  background: var(--clrVeryDarkBlue);
}

.job-card-wrapper.dark-theme .job-position-wrapper h3{
  color:#fff;
}

.job-card-body{
  padding: 48px 32px 32px 32px;
}

.logo-wrapper{
  position: absolute;
  transform: translateY(-50%);
  left:32px;
  width: 50px;
  height:50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-meta{
  display: flex;
  color:var(--clrDarkGrey);
  align-items: flex-end;
  line-height: 1em;
  margin-bottom: 13px;
}

 .job-meta-separator{
  margin: 0 12px;
  width: 4px;
  height: 4px;
  background: var(--clrDarkGrey);
  border-radius: 50%;
}

.job-position-wrapper{
  margin-bottom: 17px;
}

.company-wrapper{
  margin-bottom: 44px;
  color:var(--clrDarkGrey);
  line-height: 0;
}

.location-wrapper h4 {
  color:var(--clrViolet);
  font-size: 14px;
  font-weight: bold;
}

/*Job Details Page*/
.job-details-page-wrapper {
  overflow: auto;
  background: var(--clrLightGrey);
  transition: background var(--trTheme); 
}

.job-details-body-wrapper{
  position: relative;
  margin-top: 120px;
  margin-bottom: 80px;
  z-index: 10;
 }
 
/*Job Details Page Dark Theme*/
.job-details-page-wrapper.dark-theme{
  background: var(--clrMidnight);
}

.job-details-header,
.job-details-header .info-container{
  display: flex;
  align-items: center;
  gap:40px;
  height: 100%;
}

.job-details-header{
  justify-content: space-between;
  width: 100%;
  height: 140px;
  background: #fff;
  padding-right: 43px;
  margin-bottom: 32px;
  border-radius: 10px var(--borderRadiusMain) var(--borderRadiusMain) 10px;
  transition: background var(--trTheme);
}

/*Dark Theme*/
.job-details-header.dark-theme{
  background: var(--clrVeryDarkBlue);
}

.job-details-header.dark-theme .company-website{
  color:var(--clrDarkGrey);
}

.job-details-header.dark-theme .company-name,
.job-details-header.dark-theme .company-name h2 {
 color:#fff; 
}

.job-details-header .logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 140px;
  border-radius: var(--borderRadiusMain) 0 0 var(--borderRadiusMain);
}

.job-details-header .logo-container img{
width: 81px;
height: 40px;
}

.job-details-header .company-name {
 display: flex;
 flex-direction: column;
 gap:13px;
 transition: var(--trTheme);
}

/*Job Details Body*/
.job-details-wrapper{
  max-width:730px;
  background: #fff;
  padding: 48px 43px 48px 48px;
  border-radius: var(--borderRadiusMain);
  transition:background var(--trTheme);
}

/*Job Details Body Dark Theme*/
.job-details-wrapper.dark-theme{
  background: var(--clrVeryDarkBlue);
}

.job-details-wrapper.dark-theme  h1,
.job-details-wrapper.dark-theme h3 {
  color:#fff;
}

.job-details-body-wrapper

.job-details-body-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 87px;
}

.job-details-wrapper .job-meta {
  margin-bottom: 11px;
}

.job-details-wrapper .job-position-wrapper {
  margin-bottom: 14px;
}

.job-details-body-header .location-wrapper {
  color:var(--clrViolet);
}

.job-desc-wrapper p,
.job-requirements-wrapper p,
.job-requirements-wrapper li,
.job-tasks p,
.job-tasks li{
  color:var(--clrDarkGrey)
}
.job-requirements-wrapper li,
.job-tasks ol li {
margin-bottom:8px;
}

.job-requirements-wrapper li,
.job-tasks li {
  display: grid;
  grid-template-columns: 36px 603px;
  align-items: baseline;
}

/*Change the bullet point colors of ul*/
.job-requirements-wrapper ul li span {
  display:block;
  width: 4px;
  height: 4px;
  background: var(--clrViolet);
  border-radius: 50%;
  margin-right: 32px;
}

/*Set the ol items to custom numbering*/
.job-tasks ol{
  list-style: none;
  counter-reset: job-tasks;
}

.job-tasks ol li::before{
 display: inline-block;
 content: counter(job-tasks);
 counter-increment: job-tasks;
 color:var(--clrViolet);
 font-weight: bold; 
}

/*Job Details Footer*/
.job-details-footer-wrapper{
  width: 100%;
  height: 96px;
  background: #fff;  
}

/**Job Details Footer Dark Theme*/
.job-details-footer-wrapper.dark-theme {
  background: var(--clrVeryDarkBlue);
  color:#fff;
}

.footer-position.dark-theme {
  color:#fff;
}

.job-details-footer-body{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-company{
  color:var(--clrDarkGrey)
}


/*Responsive Design*/
/*Tablet*/
@media screen and (max-width:1110px){
  /*Navbar body Tablet*/
  .container-lg{
    max-width: 689px;
  }

   /*Searchbar Tablet*/
  .searchbar-wrapper{
   grid-template-columns: 222px 213px 252px;
  }

  .btn-1{
    width: 80px;
    height: 48px;
  }

  /*Job Card Page Tablet*/
  .job-cards-page-wrapper{
   grid-template-columns: repeat(2, 1fr);
  }

  .job-card-wrapper {
    width: 339px
  }
}

/*Responsive Design Mobile*/
@media screen and (max-width:740px){
 /*Job Cards Mobile*/
 .container-lg {
  max-width: 327px;
 }

 .job-card-wrapper{
  max-width: 327px;
 }

 .job-cards-page-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 220px;
 }

 /*Searchbar Mobile*/
 .searchbar-wrapper .input-wrapper {
  border:none
 }

 .keyword-input input{
  margin-left: 24px;
 }

 .keyword-icon-container{
  position: absolute;
  right:0;
}

.filter-icon-wrapper{
  margin-right: 24px;
  cursor: pointer;
}

.search-icon-container svg{
 top:24px;
 left: 12px;
}

.search-icon-container{
  position: absolute;
  right:-90px;
  top:-24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--clrViolet);
  border-radius: 5px;
  cursor: pointer;
}

.search-icon-container.disabled{
  background: var(--clrLightGrey);
  cursor: default;
}

.clear-button{
  top:-80px;
}

/*Job Details Header Mobile*/
.container-md{
  max-width: 327px;
}

.job-details-header{
  height: 205px;
  flex-direction: column;
  gap:0;
  position: relative;
  padding: 0;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.job-details-header .info-container{
  flex-direction: column;
}

.job-details-header .info-container .logo-container {
  position: absolute;
  left:50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.job-details-header .info-container img {
  object-fit:scale-down;
}

.job-details-header .info-container .company-name {
  margin-top: 49px;
  text-align: center;
}

.job-details-header .company-website-link{
  margin-bottom:0;
}

/*Mobile Job Details Wrapper (Body)*/
.job-details-wrapper{
  padding: 40px 24px 40px 24px;
}

.job-details-body-wrapper{
  margin-bottom:64px;
}

.size-h1{
  font-size: 20px;
  line-height: initial;
}

.job-details-body-wrapper .job-details-body-header {
  flex-direction: column;
  height: auto;
}

.apply-now-btn{
  margin-top: 50px;
  width: 100%;
  height: 48px;
}

.job-requirements-wrapper li, .job-tasks li {
  grid-template-columns:36px 242px;
}

.job-details-footer-body  h3, 
.job-details-footer-body p {
  display: none;

}

.apply-now-btn-footer {
  width: 100%;
}

}


